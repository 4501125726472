<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="default">查詢</mdb-card-header>
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="4">
                        <mdb-input label="姓名" v-model="search.name"></mdb-input>
                    </mdb-col>
                    <mdb-col md="4">
                        <mdb-input label="連絡電話" v-model="search.tel"></mdb-input>
                    </mdb-col>
                    <mdb-col md="4" class="pt-3 text-sm-right text-md-left">
                        <mdb-btn
                            color="default"
                            size="sm"
                            class="mt-3"
                            @click="
                                search.page = 1;
                                data.nowPage = 1;
                                searchdata();
                            "
                        >
                            <i class="fa fa-search"></i>
                            搜&nbsp;&nbsp;尋
                        </mdb-btn>
                        <mdb-btn
                            color="secondary"
                            size="sm"
                            class="mt-3"
                            v-show="has_search"
                            @click="
                                search.name = '';
                                search.tel = '';
                                search.page = 1;
                                data.nowPage = 1;
                                searchdata();
                            "
                        >
                            取消搜尋</mdb-btn
                        >
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th style="width:3em">#</th>
                            <th style="width:5em">姓名</th>
                            <th style="width:10em">連絡電話</th>
                            <th style="width:18em">電子郵件</th>
                            <th style="width:18em">留言時間/內容</th>
                            <th style="min-width:10em">管理者備註</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s, sk) in data.contactList" :key="`class_${sk}`">
                            <td>{{ sk + 1 }}</td>
                            <td>{{ s.name }}</td>
                            <td>{{ s.tel }}</td>
                            <td>{{ s.email }}</td>
                            <td v-html="'<small>' + s.ctime + '</small><br>' + $nl2br(s.remark)"></td>
                            <td>
                                <textarea class="form-control" v-model="s.back_remark"></textarea>
                                <mdb-btn
                                    size="sm"
                                    color="default"
                                    v-show="s.back_remark_bk != s.back_remark"
                                    @click="save_remark(s)"
                                >
                                    <i class="fa fa-save"></i>
                                    儲存
                                </mdb-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6" v-show="data.contactList.length == 0">無資料</td>
                        </tr>
                    </tbody>
                </table>
                <page
                    :nowPage="data.nowPage"
                    :totalPage="data.totalPage"
                    @switchpage="
                        (pg) => {
                            search.page = pg;
                            getData();
                        }
                    "
                />
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
    import { mdbCard, mdbCardHeader, mdbCardBody, mdbRow, mdbCol, mdbInput, mdbBtn } from 'mdbvue';
    import page from '../../components/page';
    export default {
        props: { query: { default: {} } },
        components: {
            mdbCard,
            mdbCardHeader,
            mdbCardBody,
            mdbRow,
            mdbCol,
            mdbInput,
            mdbBtn,
            page,
        },
        data() {
            return {
                search: {
                    name: '',
                    tel: '',
                    page: 1,
                },
                data: {
                    contactList: [],
                    nowPage: 1,
                    totalPage: 0,
                },
            };
        },
        mounted() {
            console.clear();
            let vue = this;
            // 把篩選的資料放到變數裡面
            for (let i in vue.search) {
                vue.search[i] = vue.query[i] ? vue.query[i] : '';
            }
            vue.search.page = vue.search.page == '' ? 1 : vue.search.page;
            this.getData();
        },
        computed: {
            // 判斷是不是有調整篩選條件
            has_search() {
                for (let i in this.search) {
                    if (i != 'page' && i != '' && this.search[i] != '') {
                        return true;
                    }
                }
                return false;
            },
        },
        methods: {
            // 搜尋資料
            searchdata() {
                let vue = this;
                let url = vue.$route.path + '?';
                for (let k in vue.search) {
                    url += `${k}=${vue.search[k]}&`;
                }
                vue.$router.push(url);
                vue.getData();
            },
            getData() {
                let vue = this;
                let query = [
                    // 取列表
                    {
                        name: 'contact',
                        data: {},
                        sort: {
                            ctime: 1,
                        },
                        pg: vue.search.page == '' ? 1 : vue.search.page,
                    },
                    // 取數量
                    {
                        name: 'contact',
                        data: {},
                        num: '1',
                    },
                ];
                if (vue.search.name != '') {
                    query[0].data = query[1].data = {
                        name: {
                            type: 6,
                            value: `%${vue.search.name}%`,
                        },
                    };
                }
                if (vue.search.tel != '') {
                    query[0].data = query[1].data = {
                        tel: {
                            type: 6,
                            value: `%${vue.search.tel}%`,
                        },
                    };
                }
                vue.$store
                    .dispatch('get_form', {
                        payload: {
                            url: `data/get_sql_data/?query=${encodeURI(JSON.stringify(query))}`,
                        },
                    })
                    .then((res) => {
                        if (res.data.status == 'ok') {
                            vue.data.contactList = res.data.contactList.map((item) => {
                                item.back_remark_bk = item.back_remark;
                                return item;
                            });
                            vue.data.totalPage = Math.ceil(res.data.contactNumber / 10);
                            vue.data.nowPage = vue.search.page;
                        }
                    });
            },
            // 儲存備註
            save_remark(s) {
                let vue = this;
                vue.$store
                    .dispatch('post_form', {
                        payload: {
                            url: `contact/saveremark_process/`,
                            data: {
                                data: JSON.stringify({
                                    id: s.id,
                                    back_remark: s.back_remark,
                                }),
                            },
                        },
                    })
                    .then(() => {
                        s.back_remark_bk = s.back_remark;
                    });
            },
        },
    };
</script>
